.container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
  max-width: 300px;
  overflow-y: auto;
  overflow-wrap: break-word;
  border-right: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
}
h2{padding-bottom:20px;}
h3{padding-top:20px;
padding-bottom:20px;}
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
p{padding-top:30px;padding-left:30px;}

.listItem {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s;
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.listItemText {
  font-size: 0.8rem;
  color: gray;
}

.listItemCheck {
  color: green;
  margin-left: 10px;
}

.listItem:hover,
.listItemActive {
  background-color: #f0f0f0;
}

.listItemActive {
  border: 2px solid #007bff;
}

.listItemCompleted {
  background-color: #e0ffe0;
  border: 2px solid transparent;
}

.mainContent {
  flex-grow: 1;
  padding: 20px 20px 20px 0;
  overflow-y: auto;
}

.bookingDetails {
  padding-left: 30px;
}

.bookingDetailsKey {
  font-weight: bold;
}

.smallButton {
  padding: 5px 10px;
  margin: 5px 0; /* Adjust vertical margin */
  font-size: 0.8rem;
  cursor: pointer;
  width: 300px; /* Adjust width to fit content */
}

.buttonGroup {
  display: flex;
  flex-direction: column;
}
