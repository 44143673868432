/* BookingDetails Specific Styles */

.booking-details {
  background: #242424; /* Dark background for contrast */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.booking-details h2 {
  color: #80ffdb; /* Heading color to match gradient */
  margin-bottom: 20px;
}

.booking-details form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Spacing between form fields */
}

.booking-details label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.booking-details input[type="text"],
.booking-details input[type="email"],
.booking-details input[type="tel"],
.booking-details textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid #80ffdb; /* Border color to match gradient */
  background-color: transparent;
  color: white;
  border-radius: 5px;
}

.booking-details input[type="checkbox"] {
  margin-top: 5px;
}

.booking-details button {
  background: linear-gradient(to right, #2e005d, #80ffdb);
  border: none;
  padding: 10px 15px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.booking-details button:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-details {
    width: 90%;
    margin: 10px;
  }
}
