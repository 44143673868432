.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
}

h3 {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 50px;
  margin-top: 0px;
}

.hamburger-menu {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
  z-index: 1001;
}

.menu-line {
  height: 3px;
  width: 100%;
  background-color: white;
  transition: all 0.3s ease;
}

.menu-line.open:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.menu-line.open:nth-child(2) {
  opacity: 0;
}

.menu-line.open:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

.sidebar {
  position: fixed;
  top: 0;
  left: -200px;
  width: 100px;
  height: 100%;
  padding-top: 60px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: left 0.5s ease;
  z-index: 1000;
}

.sidebar.show {
  left: 0;
}

.menu-item {
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
  width: 100%;
  text-align: left;
}

.menu-item:hover {
  color: #80ffdb;
}

.tile-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.tile:hover {
  border: 3px solid white;
  border-radius: 10px;
  animation: pulseBorder 2s infinite;
}

.banner-message {
  background-color: #4a90e2;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.tile {
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  text-decoration: none;
  color: inherit;
}

.tile img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.footer-links {
  font-size: 0.8em;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.portlink {
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: transparent;
  background-image: linear-gradient(to right, #5300a6, #ffffff, #80ffdb, #ffffff, #5300a6);
  background-size: 600% 100%;
  background-position: 100% 0;
  background-clip: text;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s, background-position 0.3s;
  animation: gradientAnimation 3s linear infinite;
}

.portlinkemail {
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: transparent;
  background-image: linear-gradient(to right, #ffffff, #ffffff, #d780ff, #ffffff, #ffffff);
  background-size: 600% 100%;
  background-position: 100% 0;
  background-clip: text;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s, background-position 0.3s;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
      background-position: 100% 0;
  }
  100% {
      background-position: -100% 0;
  }
}

.portlink:hover,
.portlink:focus {
  border-bottom: 2px solid transparent;
  background-position: 0 0;
}

.portlink:active {
  border-bottom: 2px solid #2e005d;
  background-position: 0 0;
}

@media (max-width: 600px) {
  .tile-container {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      padding: 0 10px;
  }
}

@keyframes pulseBorder {
  0%, 100% {
      border-color: #2e005d;
  }
  50% {
      border-color: #80ffdb;
  }
}

.location-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.location-tile {
  width: 250px;
  padding-top: 20px;
  margin: 0;
}

.back-button-container {
  text-align: left;
  margin-bottom: 0px;
  width: calc(90% - 40px);
  padding-left: calc(80% - 750px);
}

.back-button {
  background-color: #5300a6;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  width: 125px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.back-button:hover {
  background-color: #6e41a8;
}
