html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: white;
  }
  h3 
  {
    padding-top:0px;
    padding-bottom:0px;
    margin-top:0px;
    margin-bottom:0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #2e005d, #80ffdb);
    font-family: Arial, sans-serif;
    text-align: center;
    width: 100%;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
  }
  
  .info-box, .status-box {
    font-size: 15px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    margin: 10px;
    box-sizing: border-box;
  }
  
  .property-info, .info-box, .status-box {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .property-info {
    font-size: larger;
  }
  
  .status-box {
    background-color: #000000; /* Light grey background for status */
  }
  
  .content-line {
    margin-bottom: 5px; /* Spacing between lines */
  }
  
  button, .save-notes-btn, .invoice-btn, .download-btn {
    background-color: black; 
    color: #ffffff;
    font-size: 20px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    width: 40%;

  }
   .action-button-notes {
    background-color: transparent; 
    font-size: 15px;
    color: white;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 10px;
    padding: 2px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    width: 80%;
  }
  
  button:hover {
    /* Add gradient animation or other hover effect */
  }
  .form {      /* Makes the form only as wide as its content */   /* Rounded corners with a radius of 10 pixels */
    margin: 0;                   /* Removes default margin */
    padding: 0;                  /* Removes default padding */
    /* Add other styles here if needed */
  }
  @media (max-width: 768px) {
    .container {
      min-height: 100vh;
      overflow-y: auto;
    }
  
    .form, button {
      width: 70%; /* Full width on smaller screens */
    }
  
    .property-info, .info-box, .status-box {
      width: auto; /* Full width on smaller screens */
      align-items: left;
    }
  }
  h2{
    padding: 0px;
    padding-top: 10px;
    margin:0px;
  }
  .notes-textarea {
    background-color: transparent; /* Makes background transparent */
    color: white; /* Sets text color to white */
    border: 0px solid white; /* Optional: Adds a white border for better visibility */
    padding: 5px; /* Padding inside the text area */
    width: 250px;
    height: 50px;
    resize: none; 
  }
  
  /* Style for the placeholder text */
  .notes-textarea::placeholder {
    color: white;
  }
  