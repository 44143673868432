/* SpecialBooking.module.css */
.container {
  padding: 20px;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
  margin-bottom: 10px;
  color: #ffffff;
}

.input, .select {
  width: auto;
  padding: auto;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 1px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:auto;
}

.button:hover {
  background-color: #0056b3;
}
