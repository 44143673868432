/* BookingDetails Specific Styles */

.booking-details {
  background: #242424;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

.booking-details h2 {
  color: #80ffdb;
}

.booking-details form {
  display: flex;
  flex-direction: column;
}

.booking-details label {
  display: flex;
}

.booking-details input[type="text"],
.booking-details input[type="email"],
.booking-details input[type="tel"],
.booking-details textarea {
  width: 100%;
  border: 2px solid #80ffdb;
  background-color: transparent;
  color: white;
  border-radius: 5px;
}

.button-container {
  display: flex;
  gap: 1px;
}

/* Style for the "Copy" buttons */
.inline-button {
  border:#2e005d;
  border: 1px solid #80ffdb;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: auto; /* Set width to auto */
  line-height:4px;
}

/* Style for the "Call" and "Msg" buttons */
.icon-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  line-height:4px;
  width: auto; /* Set width to auto */
}

.icon-button:hover {
  transform: scale(1.05);
}

/* Other button styles */
.booking-details button {
  margin: 0px;
  margin-right: 5px;
  background: linear-gradient(to right, #2e005d, #80ffdb);
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: auto; /* Set width to auto */
}

.disabled-button:disabled {
  background: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.disabled-button:disabled:hover {
  background: #cccccc;
}

.booking-details button:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-details {
    width: 90%;
  }
}
