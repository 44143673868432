.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body{
  background:black;
}
.sidebar {
  width: 100%;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
}

h2 {
  padding-bottom: 20px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.inlineButton {
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.inlineButton:hover {
  background-color: #0056b3;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s;
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc; /* Add border */
  margin-bottom: 15px; /* Adjust spacing */
}

.listItemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.listItemText {
  /* ... existing styles ... */
  margin-bottom: 3px; /* Add bottom margin */
}

.listItemText:last-child {
  margin-bottom: 0px; /* Remove bottom margin for the last text element */
}
.bookingId {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom:4px;
}

.buttonGroup {
  display: flex;
  gap: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.smallButton {
  padding: 5px 10px; /* Adjust padding for better spacing */
  margin: 7px 10px 0px 0; /* Adjust margins */
  font-size: 0.7rem; /* Keep font size */
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block; /* Ensure button is treated as inline-block */
}
.fullEditButton {
  min-width: 80px; /* Adjust this width as needed */
}

.timeUntil {
  font-weight: bold;
}
.smallButton:hover {
  background-color: #218838;
}
.weekGroup {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.weekGroupTitle {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.userButton {
  background-color: purple;
}

.userButton:hover {
  background-color: #6a0dad;
}

.usButton {
  background-color: #28a745;
}

.usButton:hover {
  background-color: #218838;
}

.fullEditButton {
  background-color: red;
}

.fullEditButton:hover {
  background-color: darkred;
}

.listItem:hover,
.listItemActive {
  background-color: #25002b;
  
}

.listItemActive {
  border: 2px solid #007bff;
}

.mainContent {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}
