/* Extend the gradient background to cover the entire page */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom right, #2e005d, #80ffdb);
    font-family: 'Arial', sans-serif;
    color: white;
  }
  
  .serviceOption {
    padding: 10px;
    margin: 5px 0;
    border: 2px solid transparent; /* Default no border */
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.3s;
  }
  .selectContainer
  {
    padding-bottom:30px;
  }
  
  .serviceSelected {
    border: 2px solid white; /* Border to show selection */
    background-color: rgba(255, 255, 255, 0.1); /* Light background to indicate selection */
  }
  
  .container {
    color: white;
    padding: 20px;
    border-radius: 15px;
    border: 2px solid black; /* Solid black border */
    width: 80%;
    max-width: 600px;
    margin: 30px auto;
    padding-bottom: 30px;
    background-color: transparent; /* Maintain the transparent background */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 100%; /* Add full width */
  }
  
  .content-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  /* Styling for the square footage range dropdown */
  .input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none; /* Remove border */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    position: relative;
    background-repeat: no-repeat;
    text-align: center;
  }
  
  .input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Adjust the label to include padding and interaction styles */
  .content-line label {
    padding: 10px;
    width: 100%;
    display: block;
  }
  
  .action-button-notes, .continueButton  {
    background: #2e005d;
    border: 2px solid white;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px; /* Increase padding for better visual */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in the width */
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
  }
  
  .action-button-notes:hover {
    background: white;
    color: #2e005d;
  }
  
  .continueButton {
    /* Inherit styles from .action-button-notes */

  }
  
  .info-box {
    font-size: 30px;
  }
  
  @media (max-width: 768px) {
    .container {
      width: 95%;
      margin-top: 10px;
    }
  }
  
  /* Specific adjustments for Firefox */
  @-moz-document url-prefix() {
    .input {
      text-indent: 0.01px;
      text-overflow: '';
    }
  }
  